import React from "react";
import Events1 from "../img/Event1.jpg";
import Events2 from "../img/Event2.jpg";
import AnimatedDiv from "./AnimatedDiv";

const Events = () => {
  return (
    <div className="container-fluid py-5" id="events">
      <div className="container py-2">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h1 className="font-secondary display-4 mb-3">Eventos</h1>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <h5 className="font-weight-normal text-muted mb-3 pb-3"></h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div
              className="text-center text-md-left ml-md-3"
              style={{ marginBottom: "30px" }}
            >
              <img
                className="img-fluid mb-4"
                src={Events1}
                alt="party"
                style={{ height: "250px", width: "470px" }}
              />
              <h4 className="mb-3">Ceremonia religiosa - 17:45 HS</h4>
              <p className="mb-2">Sábado 22 de febrero</p>
              <p className="mb-0">
                Capilla San Cristóbal. Calle Lobo de la Vega. Yerba Buena.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://maps.app.goo.gl/bSttwtUy7vXAGY567"
              >
                <button
                  className="blue-button shine-button"
                  style={{ marginTop: "25px" }}
                >
                  CÓMO LLEGAR
                </button>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center text-md-right mr-md-3">
              <img
                className="img-fluid mb-4"
                src={Events2}
                alt="ceremony"
                style={{ height: "250px", width: "470px" }}
              />
              <h4 className="mb-3">Fiesta - 19:00 HS</h4>
              <p className="mb-2">Sábado 22 de febrero</p>
              <p className="mb-0">
                Canzonieri Park. Avenida Aconquija 3450. Yerba Buena.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://maps.app.goo.gl/bCrqDjqKPJ7pwAHH7"
              >
                <button
                  className="blue-button  shine-button"
                  style={{ marginTop: "25px" }}
                >
                  CÓMO LLEGAR
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;

import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Story1 from "../img/Story1.jpg";
import Story2 from "../img/Story2.jpg";
import Story3 from "../img/Story3.jpg";
import Story4 from "../img/Story4.jpg";
import Story5 from "../img/Story5.jpg";

const Story = () => {
  return (
    <div className="container-fluid" id="story">
      <div className="container pb-3">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h6
              className="text-uppercase text-primary mb-3"
              style={{ letterSpacing: "3px" }}
            >
              Un poco de
            </h6>
            <h1 className="font-secondary display-4">Nuestra historia</h1>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div className="container timeline position-relative p-0">
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story1} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2 text-primary-two">Nos conocimos</h4>
                <p className="m-0">
                  en el año 2017 participando de un voluntariado y compartiendo
                  proyectos. Empezamos a salir a fines de ese año
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 mr-md-3">
                <h4 className="mb-2 text-primary-two">Nuestra primera cita</h4>
                <p className="m-0">
                  fue tomando un helado, de menta granizada y de vainilla
                  granizada.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <img className="img-fluid ml-md-3" src={Story2} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story3} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2 text-primary-two">Nos pusimos de novios</h4>
                <p className="m-0">
                  en el año 2018. Desde entonces elegimos caminar juntos.
                  Tenemos amigos en común, lo que nos permitió disfrutar de
                  cumpleaños, salidas, recibidas, caminatas y momentos juntos.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 mr-md-3">
                <h4 className="mb-2 text-primary-two">Desde un inicio</h4>
                <p className="m-0">
                  compartimos las ganas por viajar, conocer las culturas que nos
                  rodean y crear vínculos nuevos. Disfrutamos del cine, las
                  series, los libros y de aprender de la historia y las
                  experiencias. Nos encanta conversar y discutir, pero también
                  reírnos de nosotros y de la vida.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <img className="img-fluid ml-md-3" src={Story4} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story5} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2 text-primary-two">En mayo de 2024</h4>
                <p className="m-0">
                  después de una buena conversación, decidimos casarnos y hacer
                  de este diálogo y de esta historia un largometraje del que
                  queremos seas parte.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;

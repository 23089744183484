import React, { useEffect } from "react";

const Loader = ({ setIsRedirecting }) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRedirecting(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [setIsRedirecting]);
  return (
    <div className="loader-page">
      <div className="loader-content">
        {/* <div className="loader cora"></div> */}
        <p className="loader-text">Aldi & Fer</p>
      </div>
    </div>
  );
};

export default Loader;

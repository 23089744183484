import React from "react";

const InitialPage = ({ setIsRedirecting, setIsLoading }) => {
  // const handleFamiliarRedirect = () => {
  //   setIsLoading(true);
  //   setIsRedirecting(true);
  //   setIsFamiliar(true);
  // };

  const handleFriendRedirect = () => {
    setIsLoading(true);
    setIsRedirecting(true);
  };

  return (
    <div className="initial-page">
      <div>
        <p className="initial-text">Aldi & Fer</p>
        <p className="secondary-text">
          Comenzamos un nuevo camino <br /> y queremos que seas parte.
        </p>
      </div>
      <div>
        <div className="initial-button-container">
          {" "}
          <button className="initial-button shine-button" onClick={handleFriendRedirect}>
            EMPEZAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialPage;

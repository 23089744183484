import React, { useState } from "react";
import "./App.css";
import Carousel from "./components/Carousel";
import Navbar from "./components/Navbar";
import Invitation from "./components/Invitation";
import Story from "./components/Story";
import Events from "./components/Events";
import Dresscode from "./components/Dresscode";
import Present from "./components/Present";
import Assistance from "./components/Assistance";
import Gallery from "./components/Gallery";
import Thanks from "./components/Thanks";
import Playlist from "./components/Playlist";
import Footer from "./components/Footer";
import GoToTopButton from "./components/GoToTopButton";
// import Testimonials from "./components/Testimonials";
import InitialPage from "./components/InitialPage";
import Loader from "./components/Loader";

function App() {
  const [play, setPlay] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  return (
    <div className="App">
      {!isLoading ? (
        <InitialPage
          setIsRedirecting={setIsRedirecting}
          setIsLoading={setIsLoading}
        />
      ) : isRedirecting ? (
        <Loader setIsRedirecting={setIsRedirecting}  />
      ) : (
        <>
          <Carousel
            play={play}
            setPlay={setPlay}
            isFinished={isFinished}
            setIsFinished={setIsFinished}
          />
          <Navbar />
          <Invitation />
          <Story />
          <Events />
          <Dresscode />
          <Present />
          <Gallery />
          <Assistance />
          {/* <Testimonials /> */}
          <Thanks play={play} setPlay={setPlay} />
          <Playlist />
          <Footer />
          <GoToTopButton />
        </> 
      )}
    </div>
  );
}

export default App;
